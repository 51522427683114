// Styling for the masthead
header.masthead {
  text-align: center;
  color: white;
  background-image: url('../../img/header-bg.jpg');
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .intro-text {
    text-shadow: 1px 1px 1px black, 0 0 1em #272626;
    .intro-lead-in {
      font-size: 22px;
      font-style: italic;
      line-height: 22px;
      margin-bottom: 25px;
      @include serif-font;
    }
    .intro-heading {
      font-size: 50px;
      font-weight: 700;
      line-height: 50px;
      margin-bottom: 25px;
      @include heading-font;
    }
  }
}

@media (min-width: 320px) {
  header.masthead {
    .intro-text {
      .intro-lead-in {
        font-size: 18px;
      }
      .intro-heading {
        font-size: 30px;
      }
    }
  }
}

@media (min-width: 575px) {
  header.masthead {
    .intro-text {
      .intro-lead-in {
        font-size: 20px;
      }
      .intro-heading {
        font-size: 46px;
      }
    }
  }
}

@media (min-width: 768px) {
  header.masthead {
    .intro-text {
      padding-top: 300px;
      padding-bottom: 200px;
      .intro-lead-in {
        font-size: 40px;
        font-style: italic;
        line-height: 40px;
        margin-bottom: 25px;
        @include serif-font;
      }
      .intro-heading {
        font-size: 75px;
        font-weight: 700;
        line-height: 75px;
        margin-bottom: 50px;
        @include heading-font;
      }
    }
  }
}
