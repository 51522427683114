section#about {
    p {
        font-size: 1rem;
    }
    p:first-child {
        margin-top: 40px;
    }
}

@media(max-width:575px) {
    section#about {
        p {
            font-size: 0.8rem;
        }
    }
}