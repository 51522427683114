// Global styling for this template
body {
  overflow-x: hidden;
  @include body-font;
}

p {
  line-height: 1.75;
}

a {
  color: $gray-700;
  &:hover {
    color: darken($gray-900, 10%);
  }
}

.text-primary {
  color: $primary !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  @include heading-font;
}

.page-section {
  padding: 100px 0;
  h2.section-heading {
    font-size: 40px;
    margin-top: 0;
    margin-bottom: 50px;
  }
  h3.section-subheading {
    font-size: 16px;
    font-weight: 400;
    font-style: italic;
    margin-bottom: 75px;
    text-transform: none;
    @include serif-font;
  }
}

@media (min-width: 768px) {
  section {
    padding: 150px 0;
  }
}

// Highlight color customization
::-moz-selection {
  background: $primary;
  text-shadow: none;
}

::selection {
  background: $primary;
  text-shadow: none;
}

img::selection {
  background: transparent;
}

img::-moz-selection {
  background: transparent;
}

@media (max-width: 575px) {
  .page-section {
    padding: 50px 0;
    h2.section-heading {
      font-size: 26px;
      margin-top: 0;
      margin-bottom: 0;
    }
    h3.section-subheading {
      margin-bottom: 30px;
    }
    .text-muted {
      font-size: 0.75rem;
    }
    .fa-stack,
    .fa-4x {
      font-size: 2.75rem;
    }
  }
}
