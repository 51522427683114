@media (min-width: 1100px) {
  .section-subheading {
    margin-top: 0px;
  }
}
@media (min-width: 320px) {
  .section-subheading {
    margin-top: 1px;
  }
}
